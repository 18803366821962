import React from 'react';
import { Button } from '@blueprintjs/core';
import './footer.scss';

export const Footer = () => {

    const handleRequestAccess = () => {
        const requestForm = process.env.REACT_APP_IT_RQUEST_FORM
        if (requestForm)
        window.open(requestForm)
    }

    return (
        <div className='footer'>
            <div className='btn-group'>
                <Button intent='primary' onClick={() => handleRequestAccess()}>Request access</Button>
            </div>
        </div>
    )
};