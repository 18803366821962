import { Backend } from "./backend";
import { Auth } from 'aws-amplify';

const SOURCE = 'OXPAY-LOGIN-ADMIN';

export const Logger = {
    writeLog(message, email = Auth.user.attributes.email, level = 'info') {
        const query = {
            message: `{${SOURCE}}:{${email} ${message}}`,
            level: LogType[level],
        };
        // Backend.postLogger("api/logger", query);
    },
}

const LogType = {
    info: 'INFO',
    error: 'ERROR',
    warn: 'WARN',
    debug: 'DEBUG',
    trace: 'TRACE',
    fatal: 'FATAL'
};