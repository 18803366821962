import React, { useState } from 'react';
import { Cell, Grid } from 'styled-css-grid';
import { Button, ButtonGroup, Checkbox, FormGroup, HTMLSelect, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import { DateInput, DateRangeInput } from '@blueprintjs/datetime';
import * as R from 'ramda';
import { StringUtils } from "../../utils";

import { buildDateInputFormatters } from "../../utils/date-input-formatters";
import { observer } from "mobx-react-lite";

export const SchemaFieldTypes = {
  // base types
  CUSTOM: 'custom',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  TITLE: 'title',
  TITLE_BUTTON: 'title-button',

  // extended types
  DATE_RANGE: 'date-range',
  INPUT_NULLABLE: 'text-nullable',
  DATE: 'date',

  // data types, order by alphabet
  GATEWAY_LIST: 'gateway-list',
};

// eslint-disable-next-line no-unused-vars
const FIELD_STRUCT = {
  label: '',
  type: '', // SchemaFieldTypes
  startDateName: '', // Result object field name
  endDateName: '', // Result object field name
  keepWhitespaces: false, // trim if false(default)
  required: false, // Required (Validation), data is null|undefined
  requiredCondition: null, // Function(data), field will not be 'required' if return value is false
  condition: null, // Function(data), field will be hidden if return value is false
  tips: null, // Tips
  width: null,
  options: null, // Select options object, format is {value: label, value2: label2}
  rules: { // Other validation rules
    startDate: false, // Start date should not be empty
    endDate: false, // End date should not be empty
    fn: undefined, // Only returns error message
  },
};

// eslint-disable-next-line no-unused-vars
const BUTTON_STRUCT = {
  text: '',
  icon: '',
  onClick: null, // Click event, parameter is (validated data, schema)
  condition: null, // Function(data), button will be hidden if return value is false
  noValidate: false,
};

export const buildData = (data, schema) => {
  const result = {};
  Object.entries(data).forEach(([name, value]) => {
    const field = schema.fields[name];
    if (!field) {
      return;
    }
    switch (field.type) {
      case SchemaFieldTypes.DATE_RANGE:
        if (!R.isEmpty(value)) {
          result[field.startDateName] = value[0];
          result[field.endDateName] = value[1];
        }
        break;
      default:
        if (!StringUtils.isEmpty(value)) {
          if (!field.keepWhitespaces && typeof value === 'string') {
            value = value.trim();
          }
          result[name] = value;
        }
    }
  });
  return result;
};

const CommonFormGroup = ({ field, defaultLabel, errorText, children }) => {
  return <FormGroup
    label={field.label || defaultLabel}
    intent={errorText && Intent.WARNING}
    helperText={field.tips || errorText}
    labelInfo={field.required && '(required)'}
  >{children}</FormGroup>;
};


export const SchemaForm = observer(({ schema, defaultData = {} }) => {
  // const data = defaultData;
  const [data, setData] = useState({ ...defaultData });
  const [errors, setErrors] = useState({});

  function onChange(fieldName, value) {
    const newData = { ...data };
    if (value === null || value === undefined || /^\s*$/.test(value)) {
      delete newData[fieldName];
    } else {
      newData[fieldName] = value;
    }

    //Handle 'Please select...' not empty issue
    if (value === 'Please select...') {
      newData[fieldName] = null;
    }
    setData(newData);
  }

  Object.entries(schema.fields).forEach(([_, field]) => {
    if (field.requiredCondition) {
      field.required = field.requiredCondition(data);
    }
  });

  function cell([name, field], onChange) {
    if (field.condition && !field.condition(data)) {
      return;
    }
    let formGroup;
    const errorText = errors[name] && errors[name].join('\n');
    switch (field.type) {
      case SchemaFieldTypes.TITLE:
        formGroup = (
          <CommonFormGroup field={field} defaultLabel="Aggregator" errorText={errorText}>
          </CommonFormGroup>
        );
        break;
      case SchemaFieldTypes.DATE:
        formGroup = (
          <CommonFormGroup field={field} defaultLabel="Date" errorText={errorText}>
            <DateInput
              {...buildDateInputFormatters('YYYY-MM-DD')}
              onChange={v => onChange(name, v)}
              value={data[name]}
              popoverProps={{ fill: true }}
              shortcuts={true}
              minDate={new Date(1900, 1, 1)}
              maxDate={new Date(2100, 1, 1)}
              disabled={false}
            />
          </CommonFormGroup>
        );
        break;

      case SchemaFieldTypes.CHECKBOX:
        formGroup = (
          <CommonFormGroup field={field} defaultLabel="Aggregator" errorText={errorText}>
            <Checkbox
              defaultIndeterminate={true}
              defaultChecked={data[name] === true}
              indeterminate={false}
              onChange={e => onChange(name, e.target.checked)}
            />
          </CommonFormGroup>
        );
        break;

      case SchemaFieldTypes.TEXTAREA:
        formGroup = (
          <CommonFormGroup field={field} errorText={errorText}>
            <TextArea
              onChange={e => onChange(name, e.target.value)}
              type={field.inputType || 'text'}
              defaultValue={data[name]}
              fill
              growVertically={field.growVertically}
            />
          </CommonFormGroup>
        );
        break;

      case SchemaFieldTypes.SELECT:
        formGroup = (
          <CommonFormGroup field={field} errorText={errorText}>
            <HTMLSelect
              onChange={e => onChange(name, e.target.value)}
              value={data[name] || '' || false}
              fill
              options={[{ value: null, label: 'Please select...' },
              ...Object.entries(field.options).map(([value, label]) => ({ value, label }))]}
            />
          </CommonFormGroup>
        );
        break;

      case SchemaFieldTypes.DATE_RANGE:
        formGroup = (
          <CommonFormGroup field={field} errorText={errorText}>
            <DateRangeInput
              {...buildDateInputFormatters('YYYY-MM-DD')}
              allowSingleDayRange
              onChange={v => onChange(name, v)}
              value={data[name]}
              popoverProps={{ fill: true }}
              startInputProps={{ fill: true, intent: errorText && Intent.WARNING }}
              defaultValue={data[name]}
              endInputProps={{
                fill: true,
                intent: errorText && Intent.WARNING,
                rightElement: <Button icon="cross" intent={Intent.WARNING} minimal onClick={() => onChange(name, undefined)} />
              }}
            />

          </CommonFormGroup>
        );
        break;

      // case SchemaFieldTypes.INPUT_NULLABLE:
      //   formGroup = (
      //     <CommonFormGroup field={field} errorText={errorText}>
      //       <InputNullable
      //         onChange={v => onChange(name, v)}
      //         type={field.inputType || 'text'}
      //         value={data[name]}
      //         fill
      //         isNull={data[field.nullName]}
      //         nullText={field.nullText}
      //         onNullChange={isNull => onChange(field.nullName, isNull)}
      //       />
      //     </CommonFormGroup>
      //   );
      //   break;

      // case SchemaFieldTypes.CURRENCY_CODE_LIST:
      //   formGroup = (
      //     <CommonFormGroup field={field} defaultLabel="CurrencyCode" errorText={errorText}>
      //       <CurrencySuggest
      //         onItemSelect={item => onChange(name, item ? item.code : null)}
      //         findFn={obj => obj.code === data[name]}
      //         intent={errorText && Intent.WARNING}
      //       />
      //     </CommonFormGroup>
      //   );
      //   break;


      case SchemaFieldTypes.TEXT:
      default:
        formGroup = (
          <CommonFormGroup field={field} errorText={errorText}>
            <InputGroup
              onChange={e => onChange(name, e.target.value)}
              type={field.inputType || 'text'}
              defaultValue={data[name]}
              fill
              disabled={field.readonly}
            />
          </CommonFormGroup>
        );

    }
    return <Cell key={name} width={field.width || 1}>{formGroup}</Cell>
  }

  function validate(cb) {
    const errors = {};
    Object.entries(schema.fields).forEach(([name, field]) => {
      const rules = field.rules;
      if (!rules && field.required === undefined) {
        return;
      }
      if (field.condition && !field.condition(data)) {
        return;
      }
      const errArr = [];
      const isEmpty = R.isNil(data[name]) || R.isEmpty(data[name]);
      if (rules) {
        switch (field.type) {
          case SchemaFieldTypes.DATE_RANGE:
            if (rules.startDate && data[name] && !data[name][0]) {
              errArr.push('Start date should not be empty');
            }
            if (rules.endDate && data[name] && !data[name][1]) {
              errArr.push('End date should not be empty');
            }
            break;
          default:
        }
      }
      if (isEmpty && field.required) {
        errArr.push('Required');
      }
      if (rules && rules.fn) {
        const result = rules.fn(data[name], isEmpty, data);
        if (result) {
          errArr.push(result);
        }
      }
      if (errArr.length) {
        errors[name] = errArr;
      }
    });
    if (R.isEmpty(errors)) {
      cb(buildData(data, schema));
    }

    setErrors(errors);
  }

  function noValidate(cb) {
    cb(buildData(data, schema));
  }

  function buttonEl(button) {
    if (!button.condition || button.condition(data)) {
      return (
        <Button
          key={button.text}
          icon={button.icon}
          onClick={() => button.noValidate ? noValidate(button.onClick) : validate(button.onClick)}
        >
          {button.text}
        </Button>
      );
    }
  }

  return (
    <Grid columns={schema.columns}>
      {Object.entries(schema.fields).map(entry => cell(entry, onChange))}
      <Cell width={schema.columns - R.reduce((a, field) => a + (field.width || 1), 0, R.values(schema.fields)) % schema.columns} />
      <Cell width={schema.columns} style={{ textAlign: 'right' }}>
        <ButtonGroup>
          {schema.buttons.map(button => buttonEl(button))}
        </ButtonGroup>
      </Cell>
    </Grid>
  );
});
