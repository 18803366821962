import {createContext} from 'react';
import {decorate, observable} from 'mobx';

class DialogStore {
  config = null;
  showChangePassword = false;
  showAccountSetting = false;
}

decorate(DialogStore, {
  config: observable,
  showChangePassword: observable,
  showAccountSetting: observable,
});

export const dialogStore = new DialogStore();

export const dialogStoreContext = createContext(dialogStore);
