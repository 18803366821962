import axiosFactory from 'axios';
import FileSaver from "file-saver";
import { Intent } from "@blueprintjs/core";
import { alertStore, dialogStore, spinnerStore } from '../stores';
import { Auth } from 'aws-amplify';
import { appsetting } from "../appsetting-config";
// import { ls } from './secure-local-storage';

const axios = axiosFactory.create({
  baseURL: appsetting.MAPBackendAPI,
});

const errorHandler = (error) => {
  spinnerStore.dec();
  // TODO retry strategy: https://github.com/axios/axios/issues/164#issuecomment-327837467
  return Promise.reject(error);
};

axios.interceptors.request.use(
  config => {
    spinnerStore.inc();
    return config;
  },
  errorHandler,
);

axios.interceptors.response.use(
  (response) => {
    spinnerStore.dec();
    return response;
  },
  errorHandler,
);

const requestFn = async (method, url, data, cb, errorCb, secure, accessToken) => {
  let obj;
  secure = false;
  if (method === 'get') {
    obj = axios.get(url, {
      params: data,
      headers: (secure === false || secure === undefined) ? undefined : { Authorization: `Bearer ${accessToken}` },
    });
  } else if (method === 'post') {
    obj = axios.post(url, data, {
      headers: (secure === false || secure === undefined) ? undefined : { Authorization: `Bearer ${accessToken}` },
    });
  } else if (method === 'postDownload') {
    obj = axios.post(url, data, {
      headers: (secure === false || secure === undefined) ? undefined : { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    });
  } else if (method === 'put') {
    obj = axios.put(url, data, {
      headers: (secure === false || secure === undefined) ? undefined : { Authorization: `Bearer ${accessToken}` },
    });
  } else if (method === 'delete') {
    obj = axios.delete(url, {
      headers: (secure === false || secure === undefined) ? undefined : { Authorization: `Bearer ${accessToken}` },
    });
  } else {
    return;
  }
  obj
    .then(resp => {
      const contentDisposition = resp.headers['content-disposition'];
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filename = /filename=(.+)/.exec(contentDisposition)[1];
        FileSaver.saveAs(new Blob([resp.data]), filename, resp.headers['content-type']);
      } else if ((resp.data.header && resp.data.header.success) || resp.data.access_token) {
        cb && cb(resp);
      } else {
        errorCb && errorCb(resp);
      }
    })
    .catch(error => {
      console.error(error);
      if (error.response) {
        if (error.response.status === 401) {
          errorCb && errorCb(error);
          return;
        }
      }

      const cancelCb = () => {
        dialogStore.config = null;
        errorCb && errorCb(error);
      };

      dialogStore.config = {
        title: 'Request failed',
        body: 'Try again?',
        onClose: cancelCb,
        buttons: [{
          text: 'Cancel',
          onClick: cancelCb,
        }, {
          text: 'Yes',
          intent: Intent.PRIMARY,
          onClick: () => {
            dialogStore.config = null;
            request(method, url, data, cb, errorCb, secure);
          }
        }],
      };
      // const r = window.confirm('Request failed, try again?');
      // if (!r) {
      //   if (errorCb) { errorCb(error); }
      // } else {
      //   request(method, url, data, cb, errorCb, secure);
      // }
    });
};

const request = async (method, url, data, cb, errorCb, secure) => {
  if (secure === false) {
    await requestFn(method, url, data, cb, errorCb, secure);
  } else {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    await requestFn(method, url, data, cb, errorCb, secure, accessToken);
  }
};

const postDownload = async (url, data, cb, errorCb, secure) => {
  await request('postDownload', url, data, cb, errorCb, secure);
};

const post = async (url, data, cb, errorCb, secure) => {
  await request('post', url, data, cb, errorCb, secure);
};

const put = async (url, data, cb, errorCb, secure) => {
  await request('put', url, data, cb, errorCb, secure);
};

const get = async (url, data, cb, errorCb, secure) => {
  await request('get', url, data, cb, errorCb, secure);
};

const del = async (url, data, cb, errorCb, secure) => {
  await request('delete', url, data, cb, errorCb, secure);
};

const axiosLogger = axiosFactory.create({
  baseURL: process.env.REACT_APP_API_LOGGER_URL
});

const postLogger = async (url, data) => {
  await axiosLogger.post(url, data);
};

export const Backend = {
  post,
  postDownload,
  put,
  get,
  del,
  postLogger,
  updateBaseURL(url) {
    axios.defaults.baseURL = url;
  },
  defaultCb(resp) {
    alertStore.add({ text: 'Success' });
  },
  defaultErrorCb(resp) {
    alertStore.add({
      icon: 'error',
      text: resp.data && resp.data.header ? `Backend Error\n\n${resp.data.header.errCode}: ${resp.data.header.errMsg}` : 'Unknown error',
    });
  },
  makeFormData(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    return formData;
  },
};
