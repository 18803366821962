import React, { useContext } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/docs-theme/lib/css/docs-theme.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/table/lib/css/table.css';
import { authStore, mainStoreContext, alertStore, authStoreContext } from './stores';
import { history } from './utils';
import './App.scss';
import { SpinnerContainer } from "./components/global/spinner-container";
import { DialogContainer } from "./components/global/dialog-container";
import { Forbidden } from './pages/forbidden';
import { Login } from './pages/auth/login';
import { Layout } from './pages/layout/layout';
import { AlertContainer } from "./components/global/alert-container";
import { OAuth } from "./utils/oauth";
import { Amplify, Auth, Hub } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { withAuthenticator } from '@aws-amplify/ui-react'
import awsmobile from './aws-exports';
import { LogoutToken } from './pages/auth/logout';
import { ls } from './utils/secure-local-storage';
import {Logger as AuditLog} from './utils/logger';

Amplify.configure(awsmobile);

OAuth.autoLogin();

export const App = observer(() => {
    function redirectFormSignUpToGoogle(data) {
        const urlData = decodeURIComponent(data);
        let emailInfo = /Email:[^\s@]+@[^\s@]+\.[^\s@]+$/g.exec(urlData);
        emailInfo = emailInfo[0].replace('Email:', '').replace('.+', '');
        if (emailInfo !== null) {
            ls.set('emailRegister', emailInfo);
        }
    }

    function getEmailByFormSignIn() {
        let signIn = document.querySelector('amplify-sign-in');
        if (signIn !== undefined && signIn !== null) {
            let emailInfo = signIn.shadowRoot.
                querySelector('amplify-form-field').
                querySelector('input').value
            ls.set('emailRegister', emailInfo);
        }
    }

    Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {

            case 'signIn':
                 data.attributes && AuditLog.writeLog(`click - button - Sign in - signed in successfully`, data.attributes.email);
                 OAuth.autoLogin();
                break;
            case 'signUp':
                AuditLog.writeLog('click - button - Create Account - signed up successfully', data.user.username);
                break;
            case 'cognitoHostedUI':
                data.signInUserSession && AuditLog.writeLog('click - button - Sign in with Google - signed in successfully', data.signInUserSession.idToken.payload.email);
                break;

            case "signIn_failure":
                authStore.loggedIn = false;
                if (data.message.includes('PreSignUp+failed+with+error+DestinationUser+not+found')) {
                    alertStore.add({ text: 'User does not exist. Please create account to continue.', isCreateUser: true });
                    redirectFormSignUpToGoogle(data);
                }
                else if (data.message.includes('User does not exist')) {
                    getEmailByFormSignIn();
                    alertStore.add({ text: 'User does not exist. Please create account to continue.', isCreateUser: true });
                }
                else if (data.message.includes('Already+found+an+entry+for+username')) {
                    alertStore.add({ text: 'Google account has been successfully created. Please try again.' });
                }
                else if (data.message.includes('PreSignUp+failed+with+error+Only+allow+sign+in+with+oxpayfinancial+email')) {
                    alertStore.add({ text: 'Only allow sign in with oxpayfinancial email' })
                }
                else {
                    alertStore.add({ text: 'Login failed' });
                }
                break;

            case "forgotPassword":
                AuditLog.writeLog('click - button - Reset password', data.username);
                break;

            case "forgotPassword_failure":
                alertStore.add({ text: 'User not found' });
                break;
        }
    });

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            authStore.setAuthState(nextAuthState);
            authStore.setAuthUser(authData);
            if (nextAuthState === AuthState.SignedIn && authData) {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        authStore.loggedIn = true;
                        ls.remove('emailRegister')
                    })
                    .catch(err => console.log(err));
            }
        });
    }, []);

    function routes() {
        let form = null;
        switch (history.location.pathname) {
            case '/logout': {
                if(authStore.loggedIn) {
                    LogoutToken();
                } else {
                    form = [<Route key="login" path="/login" component={Login} />];
                }
                break;
            }
            case '/refreshtoken': {
                break;
            }
            default: {
                if (authStore.loggedIn) {
                    form = [<Route key="layout" path="/" component={Layout} />]
                } else {
                    form = [<Route key="login" path="/login" component={Login} />];
                }
                break;
            }
        }
        return form;
    }

    const mainStore = useContext(mainStoreContext);
    const authStore = useContext(authStoreContext);
    const router = (
        <Router history={history}>
            <Switch>
                <Route path="/forbidden" component={Forbidden} />,
                {routes()}
            </Switch>
        </Router>
    );

    return [
        <div key="main" className={clsx('docs-root', mainStore.themeClass)}>
            <div className="docs-app">
                {router}
            </div>
        </div>,
        <AlertContainer key="alert" className={mainStore.themeClass} />,
        <SpinnerContainer key="spinner" />,
        <DialogContainer key="dialog" className={mainStore.themeClass} />,
    ];
});

export default withAuthenticator(App)