import { createContext } from 'react';
import { computed, decorate, observable } from 'mobx';
import { ls } from '../utils';

class AlertStore {
  queue = [];
  add(obj) {
    const onClose = obj.onClose;
    obj.onClose = () => {
      if (obj.isCreateUser) {
        this.redirectSignUp()
        this.queue.pop();
        onClose && onClose();
      }
      this.queue.pop();
      onClose && onClose();
    };
    this.queue.push(obj);
  }
  get next() {
    if (this.queue.length) {
      return this.queue[0];
    }
    return undefined;
  }

  redirectSignUp() {
    let signIn = document.querySelector('amplify-sign-in');
    let signUp = document.querySelector('amplify-sign-up')
    if (signIn !== undefined && signIn !== null) {
      signIn.shadowRoot.
        querySelector('.sign-in-form-footer').
        querySelector('amplify-button').click()
      if (ls.get('emailRegister') !== '') {
        signUp.shadowRoot.
          querySelector('amplify-form-field').
          querySelector('input').value = ls.get('emailRegister')
      }
    }
  }
}

decorate(AlertStore, {
  queue: observable,
  next: computed,
});

export const alertStore = new AlertStore();

export const alertStoreContext = createContext(alertStore);
