import { authStore, mainStore } from '../stores';
import { history } from "./history-holder";
import { Auth } from 'aws-amplify';
import { ls } from '../utils';

export const OAuth = {
    setData(data) {
        //mainStore.authUser = data.user;
        authStore.authUser = data.user;
        authStore.loggedIn = true;

        mainStore.preloaded = true;
    },

    cleanStorage() {
        authStore.loggedIn = false;
        ls.clear();
    },

    async autoLogin(cb) {
        try {
            const sessionUser = await Auth.currentAuthenticatedUser();
            if (sessionUser != null) {
                OAuth.setData({ user: Auth.user });
                return;
            }
            else {
                OAuth.cleanStorage();
                history.replace('/login');
            }

        } catch (err) {
            history.replace('/login');
        }
        history.replace('/login');
    },

    async logout() {
        try {
            await Auth.signOut()
            .then(data => {
            console.log("signed out...");
            })
            .catch(e => console.log("error: ", e));

            OAuth.cleanStorage();
            history.replace('/login');
        } catch (error) {
            console.log('error signing out: ', error);
        }
    },
    hasScope(scope) {
        //add role admin
        return true;
    },
    async checkTokenExpired() {
        try {
            const accessToken = (await Auth.currentSession()).getAccessToken();
            const exp = accessToken.payload.exp;
            const authTime = accessToken.payload.auth_time;
            const currentSeconds = Math.floor((new Date()).valueOf() / 1000);
            const isTokenNotExpired =  (currentSeconds > authTime && currentSeconds < exp);
            if (!isTokenNotExpired) {
                OAuth.cleanStorage();
                history.replace('/login');
            }
        } catch (err) {
            console.log('error: ', err);
        }

    },
};
