import { Auth } from "aws-amplify";
import { Encryption } from "./encryption";

export async function appRedirect(type, setPreloadingPage, returnUrl) {
  let urlRequest = "/";
  let authSession = await Auth.currentSession();
  let userInfo = await Auth.currentUserInfo();
  let token = authSession.getAccessToken().getJwtToken();
  let payLoad = authSession.getAccessToken().payload;
  const loginToken = {
    token: token,
    email: userInfo.attributes.email,
    returnUrl: returnUrl,
  };

  const encryptLoginToken = Encryption.encrypt(JSON.stringify(loginToken));
  const logintoken = "logintoken";
  const access_token = "access_token";
  switch (type) {
    case "admin-portal":
      urlRequest =
        process.env.REACT_APP_DASHBOARD_ADMIN_URL +
        `/${logintoken}?${access_token}=${encryptLoginToken}`;
      break;
    default:
      // if (url(window['returnUrl'])) {
      //     urlRequest = window['returnUrl'] + `/${logintoken}?${access_token}=${encryptLoginToken}`;
      // } else {
      //     urlRequest = process.env.REACT_APP_DOMAIN_WITH_PORT;
      // }
      urlRequest = process.env.REACT_APP_DOMAIN_WITH_PORT;
      break;
  }
  if (urlRequest !== "/") {
    window.location.href = urlRequest;
  }
}

export function getAppTypeFromReturnUrl(returnUrl) {
  if (returnUrl === null || returnUrl === undefined) return "";

  let originUrl = new URL(returnUrl).origin;

  switch (originUrl) {
    case process.env.REACT_APP_DASHBOARD_ADMIN_URL:
      return "admin-portal";
    default:
      return "";
  }
}
